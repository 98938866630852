import React from "react";
import Navigation from "@/components/layout/Navigation";
import Content from "@/components/layout/Content";
import "./index.scss";
import ScrollDown from "@/components/layout/ScrollDown";

const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      {/* wave and scroll down */}
      <div style={{padding: "10px 10px 0"}}><Content /></div>
      {/* nav */}
      <Navigation />
      <div style={{position: "absolute", bottom: "3vh", left: "50%", transform: "translateX(-50%)"}}>
        <ScrollDown />
      </div>
    </div>
  )
}

export default HomePage
