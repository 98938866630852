import { createHashRouter } from 'react-router-dom'
import Login from '@/pages/Login'
import App from "@/App";
import ArticlePage from "@/pages/ArticlePage";
import { Hot } from "@/pages/ArticlePage/main/Hot";
import { Category } from "@/pages/ArticlePage/main/Category";
import { Star } from "@/pages/ArticlePage/main/Star";
import React from "react";
import { Tag } from "@/pages/ArticlePage/main/Tag";
import { Latest } from "@/pages/ArticlePage/main/Latest";


const router = createHashRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/article',
    element: <ArticlePage />,
    children: [
      {
        path: "latest",
        element: <Latest />,
      },
      {
        path: "hot",
        element: <Hot />,
      },
      {
        path: "category",
        element: <Category />,
      },
      {
        path: "tag",
        element: <Tag />,
      },
      {
        path: "star",
        element: <Star />,
      },
    ]
  },
  {
    path: '/login',
    element: <Login />,
  },
])

export default router
