import { articles } from "@/pages/ArticlePage/main/Hot/data";
import { ArticleItem } from "@/pages/ArticlePage/ArticleItem";
import React from "react";

const Hot = () => {
  return (
    <div className={'j-article-container'}>
      {
        articles.map((articleItem: any) => {
          return (
            <ArticleItem
              content={articleItem.title}
              summary={articleItem.summary}
              id={articleItem.id}
              title={articleItem.title} />
          );
        })
      }
    </div>
  )
}

export { Hot }
