import React, { memo } from "react";
import './index.scss'


export const ArticleItem = memo<{
  id: number
  title: string,
  summary: string,
  content: string
}>(({id, title, summary, content}) => {
  
  return (
    <div className={'j-article-item'}>
      <div>{title}</div>
      <div>{summary}</div>
    </div>
  )
})
