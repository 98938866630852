import React, { useEffect } from "react";
import { headerMenuConfig } from "@/pages/ArticlePage/config";
import { ArticleHeaderMenuItem } from "@/pages/ArticlePage/ArticleHeaderMenuItem";
import './index.scss'
import { Avatar, Tree, TreeProps } from "antd";
import avatar from './avatar.png'
import { Outlet, useNavigate } from "react-router-dom";
import { treeData } from "@/pages/ArticlePage/data";
import { DownOutlined } from "@ant-design/icons";

const ArticlePage: React.FC = () => {
  const [focusMode, setFocusMode] = React.useState(false);
  useEffect(() => {
    // 设置页面背景为空
    document.body.style.background = 'none';
    // 在组件卸载时恢复背景
    return () => {
      document.body.style.background = '';
    };
  }, []);
  
  return (
    <>
      {focusMode ?
        <FocusMode setFocusMode={setFocusMode} /> :
        <StandardMode setFocusMode={setFocusMode} />
      }
    </>
  )
}

interface FlagProps {
  setFocusMode: any;
}

const StandardMode = (props: FlagProps) => {
  const navigate = useNavigate();
  
  const [currentMenuItem, setCurrentMenuItem] = React.useState<string>("Latest");
  
  const handleMenuItemClick = (type: string) => {
    setCurrentMenuItem(type);
  };
  // TODO 设置局部渐变背景
  return (
    <div className={'j-article-page'}>
      <header className={'j-article-header'}>
        <div onClick={() => navigate('/')} style={{marginLeft: '40px'}}>
          <Avatar shape="square" size="large" src={avatar} />
        </div>
        
        {/* 导航栏，鼠标附近渐变效果 */}
        <div className="j-article-nav">
          {headerMenuConfig.map((section) => (
            <ArticleHeaderMenuItem
              key={section.type}
              section={section}
              isActive={currentMenuItem === section.type}
              setActive={setCurrentMenuItem}
            />
          ))}
        </div>
        
        {/* 进入沉浸模式 */}
        <div onClick={() => props.setFocusMode(true)} style={{marginRight: '40px'}}>
          <Avatar shape="square" size="large" src={avatar} />
        </div>
      </header>
      
      <main className={'j-article-main'}>
        <Outlet />
      </main>
    </div>
  );
};

const FocusMode = (props: FlagProps) => {
  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };
  
  return (
    <div>
      {/* 2、沉浸模式：左侧边栏 */}
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={['0-0-0']}
        onSelect={onSelect}
        treeData={treeData}
      />
      {/* 进入标准模式 */}
      <div onClick={() => props.setFocusMode(false)} style={{marginRight: '40px'}}>
        <Avatar shape="square" size="large" src={avatar} />
      </div>
    </div>
  );
};

export default ArticlePage
