import React, { useState } from "react";
import "./index.scss";
import { Button } from "antd";
import { AppstoreAddOutlined, ReadOutlined, StepForwardOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const [position, setPosition] = useState<'start' | 'end'>('start');
  const jumpToLink = () => {
    window.open('https://www.travellings.cn/go.html');
  }
  const jumpToArticle = () => {
    navigate('/article/latest')
  }
  return (
    <div className='j-navigation'>
      <div className={'j-navigation-rd'}>
        {/* 友联接力 */}
        <div className='j-btn-gap'>
          <Button icon={<StepForwardOutlined style={{color: '#10B981'}} />}
                  iconPosition={position}
                  className={'j-jump-btn'}
                  onClick={jumpToLink}>
            <span style={{color: 'white'}}>友联接力</span>
          </Button>
        </div>
        {/* 我的文章 */}
        <div className='j-btn-gap'>
          <Button icon={<ReadOutlined style={{color: '#F97316'}} />}
                  iconPosition={position}
                  className='j-read-btn'
                  onClick={jumpToArticle}>
            <span style={{color: 'white'}}>我的文章</span>
          </Button>
        </div>
      </div>
      
      <div className={'j-navigation-rd'}>
        {/* 更多内容 */}
        <div className='j-btn-gap'>
          <Button icon={<AppstoreAddOutlined style={{color: '#F43F5E'}} />} iconPosition={position}
                  className={'j-more-btn'}>
            <span style={{color: 'white'}}>更多内容</span>
          </Button>
        </div>
        {/* 关于本人 */}
        <div className='j-btn-gap'>
          <Button icon={<UserOutlined style={{color: '#EAB308'}} />} iconPosition={position}
                  className={'j-me-btn'}>
            <span style={{color: 'white'}}>关于本人</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Navigation;
