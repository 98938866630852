import type { ReactNode } from 'react'
import { createElement as h } from 'react'

import {
  CategorySearchOutlineRounded,
  PhStar,
  PhTagBold,
  UpdatesOutlineRounded,
} from '@/components/icons/menu-collection'

export interface IHeaderMenu {
  title: string
  path: string
  type?: string
  icon?: ReactNode
}

export const headerMenuConfig: IHeaderMenu[] = [
  {
    title: '最新',
    path: '/article/latest',
    type: 'Latest',
    icon: h(UpdatesOutlineRounded),
  },
  {
    title: '最热',
    path: '/article/hot',
    type: 'Hot',
    icon: h(UpdatesOutlineRounded),
  },
  {
    title: '分类',
    path: '/article/category',
    type: 'Category',
    icon: h(CategorySearchOutlineRounded),
  },
  {
    title: '标签',
    path: '/article/tag',
    type: 'Tag',
    icon: h(PhTagBold),
  },
  {
    title: '收藏',
    path: '/article/star',
    type: 'Star',
    icon: h(PhStar),
  },
]
