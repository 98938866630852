import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Danmaku from "@/components/Brarrage/Danmaku";
import { Button, Input, Slider, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./index.scss";


const textArr = Array.from("asdfghjklqwertyuiopzxcvbnm");

const getRandomIndex = (length: number): number => Math.floor(Math.random() * length);

const getRandomText = (): string => {
  const length = Math.floor(Math.random() * 19) + 6;
  return Array(length)
    .fill(null)
    .map(() => {
      return textArr[getRandomIndex(textArr.length)];
    })
    .join("");
};

const DanmakuPage: React.FC = () => {
  const danmakuInsRef = useRef<Danmaku | null>(null);
  const [showColor, setShowColor] = useState(false);
  const colorRef = useRef("");
  const [isPaused, setIsPaused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  
  useEffect(() => {
    // 第一个参数是弹幕容器，可以传string类型的选择器，或者直接传dom元素
    // 第二个object类型的参数是可选参数，包含弹幕配置，里边的所有项均不是必填项
    const danmakuIns = new Danmaku(".danmaku-wrapper", {
      rowHeight: 60, // 弹幕轨道高度，默认40（单位px）
      speed: 120, // 弹幕速度，默认100（单位px/s）
      opacity: 1, // 弹幕透明度，默认为1，范围 0-1
      maxRow: 3, // 弹幕最大轨道数，会根据容器高度自动计算，也可以手动赋值（此处设为0表示使用自动计算高度）
      minGapWidth: 30, //弹幕之前的最小间隔宽度，默认值20（单位px）
      // 每个弹幕进入时触发
      onBulletIn() {
        console.log("====bullet in====", danmakuIns.getRestAmount());
        // if (danmakuIns.getRestAmount() < 1) {
        //   danmakuIns.pushAll(['asdasd', 'qweqwe', 'zxczxc']);
        // }
      },
      // 每个弹幕消失时触发
      onBulletOut() {
        console.log("====bullet out====", danmakuIns.getRestAmount());
      },
      // 队列中的弹幕发送完时触发（每次发送弹幕都会检查，不管用何种方式发送，手动清空队列不会触发该事件）
      onQueueRunOut() {
        console.log("====queue run out====");
        // danmakuIns.pushAll(['123','456'])
      },
    });
    
    danmakuInsRef.current = danmakuIns;
  }, []);
  
  useEffect(() => {
    if (showColor) {
      colorRef.current = (
        document.querySelector(".color-piker") as HTMLInputElement
      ).value;
    }
  }, [showColor]);
  
  useEffect(() => {
    if (danmakuInsRef.current) {
      if (isPaused) {
        danmakuInsRef.current.pause();
      } else {
        danmakuInsRef.current.resume();
      }
    }
  }, [isPaused]);
  return (
    <Wrapper className="test-danmaku">
      <div className="danmaku-wrapper" />
      <div className="danmaku-toolbar">
        <div style={{...divStyle, width: "100px"}}>
          <Slider
            min={0}
            step={0.01}
            max={1}
            defaultValue={30}
            disabled={false}
            onChange={(value): void => {
              if (danmakuInsRef.current) {
                danmakuInsRef.current.opacity = Number(value);
              }
            }}
          />
        </div>
        <div style={divStyle}>
          <Space.Compact style={{width: "100%"}}>
            <Input
              className="danmaku-text-input"
              placeholder="来一句吧..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <Button ghost>
              <EditOutlined
                onClick={(): void => {
                  if (danmakuInsRef.current) {
                    if (inputValue.trim()) {
                      danmakuInsRef.current.emit(inputValue, {
                        color: showColor ? colorRef.current : undefined,
                      });
                      setInputValue(''); // 清空输入框
                    }
                  }
                }}
              />
            </Button>
          </Space.Compact>
          {/* <Input placeholder="来一首" className="danmaku-text-input" /> */}
        </div>
        
        <div style={divStyle}>
          <Button
            ghost
            onClick={(): void => {
              if (danmakuInsRef.current) {
                const content = getRandomText();
                danmakuInsRef.current.pushAll(
                  Array(1)
                    .fill(null)
                    .map(() => <TestNode>{content}</TestNode>)
                );
              }
            }}
          >
            随机1句
          </Button>
        </div>
        
        <div style={divStyle}>
          <Button
            ghost
            onClick={(): void => {
              if (danmakuInsRef.current) {
                danmakuInsRef.current.pushAll(
                  Array(20)
                    .fill(null)
                    .map(() => <TestNode>react node</TestNode>)
                );
              }
            }}
          >
            随机20句
          </Button>
        </div>
        
        <div style={divStyle}>
          <Button
            ghost
            onClick={(): void => {
              setIsPaused((p) => !p);
              console.log(isPaused ? "暂停中/Paused" : "运行中/Running");
            }}
          >
            {isPaused ? "继续/Resume" : "暂停/Pause"}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default DanmakuPage;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.1); /* 背景颜色及透明度 */
  backdrop-filter: blur(10px); /* 模糊程度 */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  .danmaku-wrapper {
    width: 100%;
    height: 100vh;
    max-height: 500px;
  }
  .danmaku-toolbar {
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 80%;
    bottom: 0;
    margin: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1); /* 背景颜色及透明度 */
    backdrop-filter: blur(10px); /* 模糊程度 */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const TestNode = styled.div`
  width: 100px;
  height: 30px;
  background: linear-gradient(90deg, pink, red);
  border-radius: 20px;
  color: #fff;
  line-height: 30px;
  text-align: center;
`;

const divStyle = {
  padding: "2px 10px",
};
