export const articles = [
  {
    id: 1,
    title: "article1",
    summary: "article1's summary",
    content: "article1's content"
  },
  {
    id: 2,
    title: "article2",
    summary: "article2's summary",
    content: "article2's content"
  },
  {
    id: 3,
    title: "article3",
    summary: "article3's summary",
    content: "article3's content"
  },
  {
    id: 4,
    title: "article4",
    summary: "article4's summary",
    content: "article4's content"
  },
  {
    id: 5,
    title: "article5",
    summary: "article5's summary",
    content: "article5's content"
  }
]
