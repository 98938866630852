import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/index.scss'
import router from '@/router'
import { RouterProvider } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "@/store";
import '@/extension/clickshow-firework'
import '@/extension/clickshow-emerge-emoji'
import '@/extension/clickshow-emerge-word'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
)
