import React, { useCallback, useState } from "react";
import "./index.scss";
import { EpArrowDown } from "@/components/svg/EpArrowDown";

const ScrollDown: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const handleScroll = useCallback(() => {
    // 获取当前视口的高度
    const viewportHeight = window.innerHeight;
    // 计算下一个目标滚动位置
    const nextScrollPosition = scrollPosition + viewportHeight;
    
    // 使用 scrollTo 方法平滑滚动到下一个视口高度
    window.scrollTo({
      top: nextScrollPosition,
      behavior: 'smooth'
    });
    
    // 更新滚动位置
    setScrollPosition(nextScrollPosition);
  }, [scrollPosition]);
  const success = () => {
  
  };
  return (
    <div className="arrow-container" onClick={success}>
      <div className="arrow" onClick={handleScroll}>
        <EpArrowDown style={{color: "#fff"}} />
      </div>
    </div>
  )
}

export default ScrollDown;
