import React from "react";
import { categories } from './data'
import './index.scss'

const Category: React.FC<{}> = (props) => {
  return (
    // 显示一级目录
    <div className={'j-article-category'}>
      {categories.map(category =>
        <p>{category.name}</p>
      )}
    </div>
  )
}

export { Category }
