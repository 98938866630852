export const categories = [
  {
    id: 1,
    name: "开发",
  },
  {
    id: 1,
    name: "运维",
  },
  {
    id: 1,
    name: "测试",
  },
]
