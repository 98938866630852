const emojiAnimation = (() => {
  const chars = ['😀', '😁', '😂', '😃', '😄', '😅', '😆', '😇', '😈', '😉', '😊', '😋', '😌', '😍', '😎', '😏', '😐', '😑', '😒', '😓', '😔', '😕', '😖', '😗', '😘', '😙', '😚', '😛', '😜', '😝', '😞', '😟', '😠', '😡', '😢', '😣', '😤', '😥', '😦', '😧', '😨', '😩', '😪', '😫', '😬', '😭', '😮', '😯', '😰', '😱', '😲', '😳', '😴', '😵', '😶', '😷'];
  
  let flag = false; // Initialize flag
  
  function createEmoji(e, isClick) {
    const color = "#" + ("00000" + ((Math.random() * 16777215 + 0.5) >> 0).toString(16)).slice(-6);
    const id = Math.floor(Math.random() * chars.length);
    const xr = Math.ceil(Math.random() * 400) - 200;
    const yr = Math.ceil(Math.random() * 400) - 200;
    const emoji = document.createElement('b');
    emoji.textContent = chars[id];
    const size = Math.round(Math.random() * (isClick ? 10 : 0) + (isClick ? 26 : 16));
    const {pageX: x, pageY: y} = e;
    emoji.style.cssText = `z-index: 9999; color: ${color}; top: ${y}px; left: ${x}px; position: absolute; font-size: ${size}px; user-select: none;`;
    document.body.appendChild(emoji);
    
    function isInViewport(elem) {
      const rect = elem.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    
    function animate() {
      emoji.animate([
        {top: `${y}px`, left: `${x}px`, opacity: 1},
        {top: `${y + yr}px`, left: `${x + xr}px`, opacity: 0}
      ], {
        duration: 1000,
        fill: 'forwards'
      }).onfinish = () => emoji.remove();
    }
    
    function checkBoundary() {
      if (!isInViewport(emoji)) {
        emoji.remove();
      } else {
        requestAnimationFrame(checkBoundary);
      }
    }
    
    animate();
    requestAnimationFrame(checkBoundary);
  }
  
  function handleEvent(e) {
    const isClick = e.type === 'click';
    if ((isClick && !flag) || (!isClick && flag)) {
      createEmoji(e, isClick);
    }
  }
  
  return {
    init: () => {
      document.addEventListener('mousemove', handleEvent);
      document.addEventListener('click', handleEvent);
    },
    setFlag: (value) => {
      flag = value;
    }, // Method to set flag
    getFlag: () => flag // Method to get flag
  };
})();

const headerShrink = (() => {
  const shrinkOn = 300;
  
  function handleScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.querySelector('ArticleHeaderMenuItem');
    if (header) { // Check if ArticleHeaderMenuItem element exists
      if (distanceY > shrinkOn) {
        classie.add(header, 'smaller');
      } else {
        classie.remove(header, 'smaller');
      }
    }
  }
  
  return {
    init: () => {
      window.addEventListener('scroll', handleScroll);
    }
  };
})();

const classie = (() => {
  'use strict';
  
  function classReg(className) {
    return new RegExp(`(^|\\s+)${className}(\\s+|$)`);
  }
  
  const hasClass = document.documentElement.classList ?
    (elem, c) => elem.classList.contains(c) :
    (elem, c) => classReg(c).test(elem.className);
  
  const addClass = document.documentElement.classList ?
    (elem, c) => elem.classList.add(c) :
    (elem, c) => {
      if (!hasClass(elem, c)) elem.className += ` ${c}`;
    };
  
  const removeClass = document.documentElement.classList ?
    (elem, c) => elem.classList.remove(c) :
    (elem, c) => {
      elem.className = elem.className.replace(classReg(c), ' ');
    };
  
  function toggleClass(elem, c) {
    (hasClass(elem, c) ? removeClass : addClass)(elem, c);
  }
  
  return {
    hasClass,
    addClass,
    removeClass,
    toggleClass,
    has: hasClass,
    add: addClass,
    remove: removeClass,
    toggle: toggleClass
  };
})();

// Initialize modules
window.onload = () => {
  emojiAnimation.init();
  headerShrink.init();
};
