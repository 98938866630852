import React from "react";
import "./index.scss";
import Wave from "@/components/Wave";

const Content: React.FC = () => {
  return (
    <div className='j-content'>
      <div className='j-content-inner'>
        <h1>Welcome to my website</h1>
        <p>This is a simple website built with React and Three.js.</p>
      </div>
      <Wave />
    </div>
  )
}


export default Content;

