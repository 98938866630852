import React, { useEffect, useState } from 'react';
import HomePage from "@/pages/HomePage";
import Navigation from "@/components/layout/Navigation";
import DanmakuPage from "@/pages/DanmakuPage";

const App: React.FC = () => {
  const [showNav, setShowNav] = useState(false);
  
  const handleScroll = () => {
    // Check if the scroll position is greater than or equal to 100vh
    if (window.scrollY >= window.innerHeight) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };
  
  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div>
      {/* 纵向第一页 */}
      <HomePage />
      {/* 根据滚动判断是否显示导航按钮 */}
      {showNav && (
        <div style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000}}>
          <Navigation />
        </div>
      )}
      {/* 纵向第二页 */}
      <DanmakuPage />
    </div>
  );
};

export default App;
