const tags = [
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
  {id: 1, name: "tag1"},
]

export default tags;
