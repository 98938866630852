// Define a variable to store the current position in the array
let a_idx = 0;

// Function to handle the click event
function handleClick(event) {
  // Define an array of strings
  let a = ["❤富强❤", "❤民主❤", "❤文明❤", "❤和谐❤",
    "❤自由❤", "❤平等❤", "❤公正❤", "❤法治❤",
    "❤爱国❤", "❤敬业❤", "❤诚信❤", "❤友善❤"];
  
  // Create a new span element and set its text content
  let span = document.createElement('span');
  span.textContent = a[a_idx];
  
  // Update the string position variable
  a_idx = (a_idx + 1) % a.length;
  
  // Get the mouse click position
  let x = event.pageX;
  let y = event.pageY;
  
  // Set styles for the span element
  span.style.position = 'absolute';
  span.style.top = `${y - 20}px`;
  span.style.left = `${x}px`;
  span.style.fontWeight = 'bold';
  span.style.userSelect = 'none';
  span.style.color = `rgb(${~~(255 * Math.random())}, ${~~(255 * Math.random())}, ${~~(255 * Math.random())})`;
  span.style.zIndex = 999999999999999999999999999999999999999999999999999999999999999999999; // Large z-index
  
  // Append the span element to the body
  document.body.appendChild(span);
  
  // Animate the span element
  let start = null;
  let duration = 1500; // Duration of animation in milliseconds
  let initialTop = y - 20;
  let finalTop = y - 180;
  
  function animate(timestamp) {
    if (!start) start = timestamp;
    let progress = timestamp - start;
    let percent = Math.min(progress / duration, 1);
    
    // Update position and opacity
    span.style.top = `${initialTop + (finalTop - initialTop) * percent}px`;
    span.style.opacity = 1 - percent;
    
    if (percent < 1) {
      requestAnimationFrame(animate);
    } else {
      // Remove the span element once the animation is complete
      span.remove();
    }
  }
  
  requestAnimationFrame(animate);
}

// Attach the click event listener to the body
document.body.addEventListener('click', handleClick);
