import React, { memo } from "react";
import { IHeaderMenu } from "@/pages/ArticlePage/config";
import { useNavigate } from "react-router-dom";
import './index.scss'
import { Simulate } from "react-dom/test-utils";
import classNames from "classnames";

// 使用memo高阶函数，优化渲染（缓存组件结果，减少重复渲染）
export const ArticleHeaderMenuItem = memo<{
  section: IHeaderMenu
  isActive: boolean
  setActive: any
}>(({section, isActive, setActive}) => {
  const navigate = useNavigate();
  const jumpNav = (clickEvent: any) => {
    navigate(section.path);
    setActive(section.type)
  }
  return (
    <div
      onClick={jumpNav}
      className={classNames('j-article-menu-item', {'active': isActive})}
    >
      {section.icon}<span>{section.title}</span>
    </div>
  )
})
