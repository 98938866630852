import React from 'react';
import { Flex, Tag as AntdTag } from 'antd';
import './index.scss'
import tags from './data'

const Tag: React.FC = () => (
  <div className={'j-article-tag'}>
    <Flex gap="4px 0" wrap>
      {tags.map(
        item =>
          <AntdTag color={getRandomColor()}>
            {getRandomColor()}
          </AntdTag>
      )}
    </Flex>
  </div>
);

const colors = [
  'magenta', 'red', 'volcano', 'orange',
  'gold', 'lime', 'green', 'cyan',
  'blue', 'geekblue', 'purple'
];

// 获取随机颜色
const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export { Tag };
